import * as React from 'react';
import DemoButtons from './Utiilities/DemoButtons';
// import ImageSction1 from "../Assests/Images/Ed365/ImageSction1.png"
import Plans from './Utiilities/Plans';
import Accordion from './Utiilities/AccordionItem';
import SpecailButtons from './Utiilities/SpecialButtons';
import Header from './Header and Footer/Header';
import Footer from './Header and Footer/Footer';
import G2Badge from './Utiilities/G2Badge';
import 'swiper/css';
import 'swiper/css/pagination';
import TabsWithImage from './Utiilities/TabsWithImage';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import Availability from './Utiilities/Availability';
import Integration from './Utiilities/Integration';
import OurClients from './Utiilities/OurClients';
import { MyContext } from '../App';
import { HelmetProvider, Helmet } from "react-helmet-async";
import { useEffect, useState } from 'react';
import ImageSction1 from "../Assests/Images/Ed365/ImageSection1.png";
import GetOfferPage from './Utiilities/GetOfferPage';
function TS365() {
    const AppName = React.useContext(MyContext)
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    
    React.useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 767);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
}, []);
    const dynamicImages = [
        'https://ik.imagekit.io/zn4au2jftpm5/hr365/LOGO/Client%20logos1_RPf_AeXggA.png?updatedAt=1700627745162',
        'https://ik.imagekit.io/zn4au2jftpm5/hr365/LOGO/Client%20logos2_fZX_JD70Nu.png?updatedAt=1700627744112',

    ];
    const tabs = [
        { id: 1, title: 'Microsoft Time Tracking', Heading: 'Microsoft Time Tracking Software for all businesses', content: (
            <ul>
                <li>
                Transform project management with Timesheet 365, a cloud-based timesheet software for businesses of all sizes.
                </li>
                <li>
                This time tracking app offers detailed insights into time allocation and activities, enhancing billing accuracy and operational efficiency.
                </li>
                <li>
                Its intuitive interface ensures precise tracking and a comprehensive view of past activities for data-driven decision-making and optimized workflows.
                </li>
                <li>
                Seamless integration with other Microsoft 365 tools enhances workflow efficiency and provides a unified view of project and time data.
                </li>
            </ul>
        ), image: 'https://ik.imagekit.io/zn4au2jftpm5/Apps365/GIF/Timesheet365/TimeTrack_mOyvlDqZgm.gif?updatedAt=1720167132158' },
        { id: 2, title: 'Seamless Collaboration', Heading: ' Seamless Collaboration with Employee Time Tracking Software', content: (
            <ul>
                <li>
                Efficiently manage timesheets with our time tracking app, designed for easy reporting for users and managers.
                </li>
                <li>
                Microsoft time tracking software offers smooth time logging, powerful reporting tools, and flexible filtering options.
                </li>
                <li>
                From high-level overviews to detailed breakdowns, it supports seamless collaboration and informed decision-making.
                </li>
                <li>
                Automated time capture and reminders reduce manual entries and ensure accurate logging of work hours.
                </li>
            </ul>
        ), image: 'https://ik.imagekit.io/zn4au2jftpm5/Apps365/GIF/Timesheet365/SeamlessCollab_iYj0QjoIJ6.gif?updatedAt=1720167132341' },
        { id: 3, title: 'Review Workflow ', Heading: 'Review Workflow in the SharePoint Time tracking  ', content:(
            <ul>
                <li>
                SharePoint timesheet app streamlines the approval process for enhanced workflow efficiency.
                </li>
                <li>
                Upon task completion or weekly submission, it automatically triggers an approval workflow.
                </li>
                <li>
                Managers can review entries for accuracy and compliance, with users locked out from editing approved entries.
                </li>
                <li>
                SharePoint Timesheet also offers responsive mobile layouts for managers to review and approve timesheets from any device.
                </li>
            </ul>
        ), image: 'https://ik.imagekit.io/zn4au2jftpm5/Apps365/GIF/Timesheet365/ReviewWorkflow_oH_zTtrwNo.gif?updatedAt=1720167132438' },
        {
            id: 4, title: 'Unlimited Projects ', Heading: 'Unlimited Projects & Clients in Our SharePoint Timesheet  ', content:(
                <ul>
                    <li>
                    SharePoint Timesheet app streamlines project management by enabling easy creation and oversight of customer accounts, tracking billable and non-billable hours, and setting custom rates for invoicing.
                    </li>
                    <li>
                    It integrates a centralized repository within employee timesheet software for easy document management.
                    </li>
                    <li>
                    Key features include unlimited projects and clients, built-in stopwatch/timer, real-time report generation, and automatic timesheet approval.
                    </li>
                   
                </ul>
            ),
            image: 'https://ik.imagekit.io/zn4au2jftpm5/Apps365/GIF/Timesheet365/Unlimitedprojectandclient_M-TLMLTJ5Q.gif?updatedAt=1720167132311'
        },
        {
            id: 5, title: 'Reports', Heading: 'Generate Reports with Microsoft 365 time tracking app  ', content: (
                <ul>
                    <li>
                    SharePoint Timesheet app provides comprehensive insights into project timelines and activities.
                    </li>
                    <li>
                    Track individual time entries and generate real-time reports exportable to Excel.
                    </li>
                    <li>
                    This Microsoft time tracking software offers detailed monthly, weekly, and project-based reports, highlighting billable vs. non-billable hours and team performance for informed decision-making and streamlined project management.
                    </li>
                    <li>
                    Customizable report templates allow you to tailor data presentations to specific project needs or stakeholder requirements.
                    </li>
                </ul>
            ),
            image: 'https://ik.imagekit.io/zn4au2jftpm5/Apps365/GIF/Timesheet365/Reports_EZrX33OX9.gif?updatedAt=1720167132311'
        },
    ];
    const planData = [
        {
            name: 'Standard',
            price: '$49',
            sup: '99',
            text: 'Standard Plan Features',
            textY: 'per month, billed yearly',

            features: [
                'Upto 10 users',
                '2 support tickets per year',
                'Free updates via Microsoft Store',
                'Integrates with Office 365',
                'SharePoint-hosted app',
                'Data stays in your SharePoint',
                'Weekly / Daily timesheets',
                'Selection of Project / People',
                'Manager ',
                'Auto approve timesheets',
                'Track hours, log hours & live tracking',
                'Draft / Submit / Approve timesheets',
                'Project / Tasks tracking',
                'Daily / Weekly / Monthly reports'
            ],
        },
        {
            name: 'Plus',
            price: '$74',
            sup: '99',
            textY: 'per month, billed yearly',
            text: 'Everything in Standard and...',
            features: [
                '20 users',
                '4 support tickets per annum',
                'Clone weekly timesheets',
                'Detailed project report',
                'Billable/non-billable hours tracking',
                'Configure flat hourly cost for projects',
                'Four user roles',
                'My team report',
                'Configure user-specific hourly cost',
                'Bulk project/task creation',
                'Add custom columns',
                'Self-assign task option'
            ]

        },
        {
            name: 'Premium',
            price: '$89',
            sup: '99',
            textY: 'per month, billed yearly',
            text: 'Everything in Plus, and...',
            features: [
                'Up to 20 users',
                'Additional users at $1/user',
                'Unlimited support',
                'Free updates with support',
                'Configure user-specific rates',
                'Configure flat hourly cost for projects',
                'Additional Program Manager role',
                'My team report',
                'Pending weekly timesheet reminders',
                'Bulk project/task/activities creation',
                'Activity tracking',
                'Select weekdays for time tracking',
                'Add Office 365 external users (optional)',
                'Select days for time tracking',
                'Disable projects'
            ]
        },
        {
            name: 'Enterprise',
            price: '$119',
            sup: '99',
            textY: 'per month, billed yearly',
            text: 'Everything in Premium and...',
            features: [
                'Upto 20 users',
                'Additional users at $2/user',
                'Configure start day of the week',
                'Integrate with Time Off Manager Plus',
                'Project end date & notifications',
                'Email templates',
                'Delete projects',
                'Add Office 365 external users',
                'Customize with Power Automate',
                'API connectivity',
                'Connect with Power BI',
                'Dedicated Account Manager',
                'One customization free*'
            ],

        },
    ];
    const accordionItems = [
        { title: `1. Does the Microsoft Time tracking app integrate with other Microsoft 365 tools?`, content: `Yes, the Microsoft Time tracking app seamlessly integrates with other Microsoft 365 tools like Teams, Outlook, and SharePoint, enabling efficient collaboration and data sharing across the organization.` },
        {
            title: `2. Can I customize the SharePoint Timesheet app to fit my organization’s needs?`, content: `Yes, the SharePoint Timesheet app is highly customizable. You can tailor fields, forms, workflows, and approval processes to match your organization’s specific time-tracking requirements.`
        },
        {
            title: `3. Can I install multiple instances of Timesheet 365? Can I install multiple instances of Timesheet 365?`,
            content: `Yes, you can install multiple instances of the application as long as it is in one Microsoft 365 tenant and total number of users of all the instances installed remain within the user limit as per your plan or license you purchased.`
        },
        {
            title: `4. How to add task details in weekly bases?`,
            content: `Go to Home page.

Select Timesheet tab. Under that select Weekly Timesheet.

Select the week for which you want to add your projects & task details and click on Add button.

Select the Project, Task and Activity Type from the dropdown list and fill the time spent on a particular task for a selected week, which will automatically calculate

the Total hours spent.`
        },
        {
            title: `5. Can we clone a weekly timesheet while adding a new weekly timesheet?`,
            content: `Yes, you can clone previous week’s timesheet to the new timesheet if you have worked on the same project & task.

Go to Home page.

Select Timesheets tab.

Choose Clone Weekly Timesheet tab and then click on Clone button.

Project will be added to your Weekly timesheet.

You can mention the time spent for a particular task on each day of the week and click on Submit button.`
        },
        {
            title: `6. How to add a task?`,
            content: `Go to Home page.

Select the Task tab.

Once the pop-up opens, choose the Project, and enter the Task Name for the respective project.

Then click on submit button. This task would be added into the task list which will fetch while entering your daily timesheet.`
        },
        {
            title: `7. How to assign a task to an employee?`,
            content: `Select the Assign Task tab.Once the pop-up opens, select the project name and task name and choose a person to whom you want to assign to and click on Submit.ss`
        },
        {
            title: `8. How to change the theme color in Timesheet Plus - ${AppName} application?`,
            content: `After logging into application, please click on Settings tab on the side-navigation.

In Settings, choose the Pre-defined Themes or you can define your Custom-Themes.`
        },
        {
            title: `9. How to add new client?`,
            content: `Go to Administrator tab, select Clients.

Once pop-up opens, click on Add button.

You can fill all the client details, and their contact details. If they have documents, you can also attach it.

Once done, you can click on submit button.`
        },
        {
            title: `10. What is the role of Project Observer?`,
            content: `A Project Observer can view all aspects of a Project. Observers cannot make any changes to the project. Project Observer is a read-only role.`
        },


    ];
    const [isLightboxOpen, setLightboxOpen] = React.useState(false);
    const [selectedImageUrl, setSelectedImageUrl] = React.useState('');

    const openLightbox = (imageUrl) => {
        setSelectedImageUrl(imageUrl);
        setLightboxOpen(true);
    };

    const closeLightbox = () => {
        setLightboxOpen(false);
    };
    const slidesData = [


        {
            id: 1,
            name: 'Wessam Baroudi',
            rating: 5,
            company: 'Head Of Information & Technology  ',
            testimonial: `Having recently joined ABC Mobility, I quickly realised we needed to invest in a helpdesk to streamline the IT operation and be the one-stop shop to manage all our users’ issues and requests.I evaluated several products, and I have decided on ${AppName} . Being a customizable SharePoint system, ${AppName} met most of our requirements. We wanted a system that is capable of multi teams and ${AppName} delivers this out of the box.`,
        },
        {
            id: 2,
            name: 'Jason Gardner',
            rating: 5,
            company: 'Director of Infrastructure ',
            testimonial: 'We were operating in an environment where we received support requests in many ways; email, teams messages, texts, phone calls, etc. For a smaller organization, this presented many challenges such as confusion within the teams as to the best way to receive service, no way to track service issues or verify they were completed and when, etc. We evaluated several options for a ticketing system, including building our own within the MS environment.',
        },
        {
            id: 3,
            name: 'Corey Jackson',
            rating: 5,
            company: 'Barton Associates ',
            testimonial: `Great App for SharePoint or MS Teams! 
We currently use within SharePoint and MS Teams to help employees find one another. We have been very happy with functionality and support provided by ${AppName}.`,
        },
        {
            id: 4,
            name: 'JP Nicols',
            rating: 5,
            company: 'Cofounder',
            testimonial: `Outstanding tech support! We use ${AppName} a little differently than most. We have a small internal team, but 1000+ association members and we use ${AppName} as a member directory. We had a rather complex permissions issue with making sure our members ("guest users" in MSFT parlance) have full access to everyone's info and Frank and the team spent nearly three hours extremely late on their Friday night making sure we got it resolved.`,
        },
        {
            id: 5,
            name: ' John Persons',
            rating: 5,
            company: 'Vice President of Operations ',
            testimonial: `Great product AND great support!
Having come from a very old version of Employee Directory 365, Frank and Sakshi were fantastic through the upgrade and implementation process of the new Modern SharePoint installation. All questions were answered in a timely fashion with remote support utilized where needed to address in full. It's great to see this level of service from a company. `,
        },

    ];
    const [currentIndex, setCurrentIndex] = React.useState(0);

    React.useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % slidesData.length);
        }, 2000); // Move to the next slide every 2 seconds for a smoother effect

        return () => clearInterval(interval); // Clear interval on component unmount
    }, []);

    const visibleLogos = Array.from({ length: 3 }).map((_, i) =>
        slidesData[(currentIndex + i) % slidesData.length]
    );
    return (
        <>
        <HelmetProvider>
                <Helmet>
                    <title>Timesheet 365: Smarter Time Tracking Made Simple</title>
                    <meta name="description" content="Track time, manage tasks, and boost productivity seamlessly within Microsoft 365. Easy to use, secure, and free for up to 1 user forever!" />
                </Helmet>
            {/* <ScrollToTop/> */}
            <Header />
            <div className='HR_panel'>
                <div className="HR_FlexCenterSpcBetween HR_ParentSection1" >

                    <div className='HR_Section1LeftSide'>
                        <h1 className='HR_primaryclr '>Turn Time into Productivity: Experience Seamless Tracking with Timesheet 365</h1>
                        <p className='HR_textclr'>
                        Effortlessly manage projects, tasks, and time with our powerful, user-friendly solution designed for Microsoft 365. Timesheet 365 brings simplicity and precision to your team’s time tracking, empowering you to focus on what truly matters – achieving your goals. Start your journey toward smarter work today!</p>

                    </div>
                    <div className='HR_Section1RightSide'>
                        {/* <img alt='MainImage' src={ImageSction1} loading="lazy" /> */}
                        <img className='BannerImageStyles' alt='MainImage' src="https://ik.imagekit.io/zn4au2jftpm5/Apps365/productPAGES/TimeSheet.png?updatedAt=1718776765041" loading="lazy" />
                    </div>
                </div>
            </div>
          {/* <GetOfferPage Type="Image"/> */}
            <div>
                <G2Badge
                    heading="Now Timesheet 365 App is Available on Microsoft Teams"
                    imageUrl="https://ik.imagekit.io/zn4au2jftpm5/hr365/SPFxEDP/site-timesheet/image_2023_11_20T15_46_44_813Z_sM-TG7WPm.png?updatedAt=1700551105010"
                />

            </div>
            <div id="features" className="IdChanges"></div>
            <div >
                <TabsWithImage tabs={tabs} />
            </div>
            <div>
                <Availability />
            </div>

            <div id="client" className="IdChanges"></div>
            <div className='HR_whitesection'>
                <h2 className='HR_heading HR_MT' >Our Clients
                </h2>
                <div className='HR_MT'>
                    <OurClients />
                </div>
            </div>


            <div id="integration" className="IdChangesIntegration"></div>
            <div className='integartionPT'>
                <Integration appName={"Timesheet 365"} />

            </div>


           
            <div className='HR_whitesection'>
              
                <div  className="IdChangesIntegration"></div>
                <h2 className='HR_heading HR_MT' id="pricing" >Timesheet 365 Plans
                </h2>
                <div className="HR_MT">
                    <div>
                        <Plans plans={planData} />
                    </div>
                </div>
                <div className='AzureLogoText'>
                        <a href='https://appsource.microsoft.com/en-ca/product/web-apps/hr365bizapps365.ts365p5a001?tab=PlansAndPrice' target='_blank'>
                            <img className='AzureImage' src='https://ik.imagekit.io/zn4au2jftpm5/Azure_dQeGdjXNs_cWe2-VVEx.jpeg?updatedAt=1736158710879' alt='Azure' />
                        </a>
                        <p className='AzureText'>
                            Using Azure? now you can <a href='https://appsource.microsoft.com/en-ca/product/web-apps/hr365bizapps365.ts365p5a001?tab=PlansAndPrice' target='_blank'>
                                buy
                            </a>
                            on Azure Marketplace or using your enterprise agreement
                        </p>
                    </div>
                <SpecailButtons  userCount={"500+"}  />
                <h3 className='HR_heading HR_MT'>Get started with 14 days risk free trial. <br />No credit card details required
                </h3>
                <div className='Hr_flex HR_MT'>
                    <DemoButtons />
                </div>
            </div>
            <span className='privacytext'>*One Free Customization (upto 4 hours)</span>
            <div id="faq" className="IdChangesIntegration"></div>
            <div className='HR_FAQ' >

                <div>
                    <h3 className='HR_faq'>Frequently Asked Questions</h3>
                    <Accordion items={accordionItems} />
                </div>


            </div>
            <Footer /> 
            </HelmetProvider>
            </>
    )
}
export default TS365;