import React from 'react';
import Lightbox from './Lightbox';
import CyberSecurity from './CyberSecurity';
import LogoSlider from './Slider';
const logos = [
    'https://ik.imagekit.io/zn4au2jftpm5/Apps365/Newclients/logo5_XEHj6nvWJB.png?updatedAt=1724243961552',
    'https://ik.imagekit.io/zn4au2jftpm5/Apps365/Newclients/logo8_SCUS88b3Cc.png?updatedAt=1724243961399',
    'https://ik.imagekit.io/zn4au2jftpm5/Apps365/Newclients/logo7_otDTw59aTX.png?updatedAt=1724243961458',
    'https://ik.imagekit.io/zn4au2jftpm5/Apps365/Newclients/logo9_OYEpkUq6z.png?updatedAt=1724243961452',
    'https://ik.imagekit.io/zn4au2jftpm5/Apps365/Newclients/logo1_cAXEfyb7NY.png?updatedAt=1724243961384',
    'https://ik.imagekit.io/zn4au2jftpm5/Apps365/Newclients/logo2_f5r9mZMp1q.png?updatedAt=1724243961334',
    'https://ik.imagekit.io/zn4au2jftpm5/Apps365/Newclients/logo10_ebFv0-z6y.png?updatedAt=1724243961274',
    'https://ik.imagekit.io/zn4au2jftpm5/Apps365/Newclients/logo1_cAXEfyb7NY.png?updatedAt=1724243961384',
    ''
];

const G2Badge = ({ heading, imageUrl }) => {
    const [isLightboxOpen, setLightboxOpen] = React.useState(false);
    const [selectedImageUrl, setSelectedImageUrl] = React.useState('');

    const openLightbox = (imageUrl) => {
        setSelectedImageUrl(imageUrl);
        setLightboxOpen(true);
    };

    const closeLightbox = () => {
        setLightboxOpen(false);
    };

    return (
        <>
            <div className='Badgesection'>
                <h2 className='Badgeheading'>Trusted by 11700+ businesses from 167 countries</h2>
                <div className='BadgeShoworHideDesktop'>
                <div className='Hr_flex badgedistance '>
                    <div className='imgwidth'>
                        <img src="https://ik.imagekit.io/zn4au2jftpm5/hr365/Badges/HelpDesk_HighPerformer_EMEA_HighPerformer_pX7e9_6NNs.png?updatedAt=1735035467902" alt="logo" />
                    </div>
                    <div className='imgwidth'>
                        <img src="https://ik.imagekit.io/zn4au2jftpm5/hr365/Badges/HelpDesk_BestRelationship_Mid-Market_Total-2_m00x_XndH.png?updatedAt=1735035467825" alt=" logo" />
                    </div>
                    <div className='imgwidth'>
                        <img src="https://ik.imagekit.io/zn4au2jftpm5/hr365/Badges/HelpDesk_HighPerformer_Enterprise_Americas_HighPerformer-1_TMQ00RdYmh.png?updatedAt=1735035467964" alt="logo" />
                    </div>
                    <div className='imgwidth'>
                        <img src="https://ik.imagekit.io/zn4au2jftpm5/hr365/Badges/ServiceDesk_Leader_Mid-Market_Leader_Gm74RX9R3n.png?updatedAt=1735035467623" alt="logo" />
                    </div>
                    <div className='imgwidth'>
                        <img src="https://ik.imagekit.io/zn4au2jftpm5/hr365/Badges/ITServiceManagementITSMTools_BestEstimatedROI_Mid-Market_Roi_WopCWvQQ6V.png?updatedAt=1735035467786" alt="logo" />
                    </div>
                    <div className='imgwidth'>
                        <img src="https://ik.imagekit.io/zn4au2jftpm5/hr365/Badges/ITServiceManagementITSMTools_FastestImplementation_Mid-Market_GoLiveTime_gCVwQXQ7A.png?updatedAt=1735035467739" alt="logo" />
                    </div>
                    <div className='imgwidth'>
                        <img src="https://ik.imagekit.io/zn4au2jftpm5/hr365/Badges/CustomerSelf-Service_HighPerformer_Americas_HighPerformer_kYs_EvVKcX.png?updatedAt=1735035468038" alt="logo" />
                    </div>
                    <div className='imgwidth'>
                        <img src="https://ik.imagekit.io/zn4au2jftpm5/hr365/Badges/HelpDesk_BestRelationship_Mid-Market_Total-2_m00x_XndH.png?updatedAt=1735035467825" alt="logo" />
                    </div>
                </div>
                </div>
                <div className='BadgeShoworHideMobile'>
                <div className='Hr_flex badgedistance '>
                    <div className='imgwidth'>
                        <img src="https://ik.imagekit.io/zn4au2jftpm5/hr365/Badges/HelpDesk_HighPerformer_EMEA_HighPerformer_pX7e9_6NNs.png?updatedAt=1735035467902" alt="logo" />
                    </div>
                    {/* <div className='imgwidth'>
                        <img src="https://ik.imagekit.io/zn4au2jftpm5/hr365/Badges/HelpDesk_BestRelationship_Mid-Market_Total-2_m00x_XndH.png?updatedAt=1735035467825" alt=" logo" />
                    </div> */}
                    <div className='imgwidth'>
                        <img src="https://ik.imagekit.io/zn4au2jftpm5/hr365/Badges/HelpDesk_HighPerformer_Enterprise_Americas_HighPerformer-1_TMQ00RdYmh.png?updatedAt=1735035467964" alt="logo" />
                    </div>
                    <div className='imgwidth'>
                        <img src="https://ik.imagekit.io/zn4au2jftpm5/hr365/Badges/ServiceDesk_Leader_Mid-Market_Leader_Gm74RX9R3n.png?updatedAt=1735035467623" alt="logo" />
                    </div>
                    <div className='imgwidth'>
                        <img src="https://ik.imagekit.io/zn4au2jftpm5/hr365/Badges/ITServiceManagementITSMTools_BestEstimatedROI_Mid-Market_Roi_WopCWvQQ6V.png?updatedAt=1735035467786" alt="logo" />
                    </div>
                    {/* <div className='imgwidth'>
                        <img src="https://ik.imagekit.io/zn4au2jftpm5/hr365/Badges/ITServiceManagementITSMTools_FastestImplementation_Mid-Market_GoLiveTime_gCVwQXQ7A.png?updatedAt=1735035467739" alt="logo" />
                    </div>
                    <div className='imgwidth'>
                        <img src="https://ik.imagekit.io/zn4au2jftpm5/hr365/Badges/CustomerSelf-Service_HighPerformer_Americas_HighPerformer_kYs_EvVKcX.png?updatedAt=1735035468038" alt="logo" />
                    </div>
                    <div className='imgwidth'>
                        <img src="https://ik.imagekit.io/zn4au2jftpm5/hr365/Badges/HelpDesk_BestRelationship_Mid-Market_Total-2_m00x_XndH.png?updatedAt=1735035467825" alt="logo" />
                    </div> */}
                </div>
                </div>
                <div>
                    <LogoSlider logos={logos} />
                </div>
            </div>

            <div className='App_whitesectionG2'>
                <h2 className='HR_heading'>{heading}</h2>
                <div className='teamsmain HR_MT Hr_flex'>
                    <img
                        style={{ borderRadius: '1vw' }}
                        alt='Teams'
                        src={imageUrl}
                        loading="lazy"
                        onClick={() => openLightbox(imageUrl)}
                    />
                    <Lightbox
                        isOpen={isLightboxOpen}
                        onClose={closeLightbox}
                        imageUrl={selectedImageUrl}
                    />
                </div>
            </div>

            <div className='cybersection' >
                <CyberSecurity />
            </div>


        </>
    );
}

export default G2Badge;
