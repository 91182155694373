import * as React from "react";
import DemoButtons from "./Utiilities/DemoButtons";
import ImageSction1 from "../Assests/Images/Ed365/ImageSection1.png";
import ImageSlider from "./Utiilities/ImageSlider";
import Plans from "./Utiilities/Plans";
import Accordion from "./Utiilities/AccordionItem";
import Lightbox from "./Utiilities/Lightbox";
import SpecailButtons from "./Utiilities/SpecialButtons";
import Footer from "./Header and Footer/Footer";
import Header from "./Header and Footer/Header";
import ScrollToTop from "./Utiilities/ScrolltoTop";
import G2Badge from "./Utiilities/G2Badge";
import TabsWithImage from "./Utiilities/TabsWithImage";
import Availability from "./Utiilities/Availability";
import OurClients from "./Utiilities/OurClients";
import Integration from "./Utiilities/Integration";
import { MyContext } from "../App";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { useEffect, useState } from 'react';
import GetOfferPage from "./Utiilities/GetOfferPage";
function PM365() {
  const AppName = React.useContext(MyContext)
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    
        React.useEffect(() => {
            const handleResize = () => {
                setIsMobile(window.innerWidth <= 767);
            };
    
            window.addEventListener('resize', handleResize);
    
            return () => {
                window.removeEventListener('resize', handleResize);
            };
    }, []);
  const tabs = [
    { id: 1, title: 'Appraisals', Heading: `Simplify appraisals using ${AppName} Performance Management Software`, content: (
      <ul>
        <li>
        Efficiently manage employee evaluations with our performance management software.
        </li>
        <li>
        Customize appraisal cycles and rating scales for Microsoft Performance Management to align with your goals.
        </li>
        <li>
        Apps365 provides a collaborative review environment for enhanced accuracy and efficiency.
        </li>
        <li>
        Integrated feedback tools allow real-time comments and assessments from multiple reviewers, ensuring a comprehensive evaluation process.
        </li>
      
      </ul>
    ), image: 'https://ik.imagekit.io/zn4au2jftpm5/Apps365/GIF/PM365/apprasalcycle_u9-4idRi8.gif?updatedAt=1720501713899' },
    { id: 2, title: 'Acknowledge', Heading: 'Evaluate and Acknowledge Employee Contributions', content:  (
      <ul>
        <li>
        Enhance evaluations with our employee performance software, supporting multi-reviewer assessments and weighted reviews.
        </li>
        <li>
        It integrates seamlessly into HR365 HRMS or operates independently for effective employee performance management.
        </li>
        <li>
        Customizable performance metrics and goals allow for tailored evaluations that align with individual and organizational objectives.
        </li>
        <li>
        Detailed reporting and analytics provide insights into performance trends, helping to identify strengths and areas for improvement.
        </li>
       
      </ul>
    ),  image: 'https://ik.imagekit.io/zn4au2jftpm5/Apps365/GIF/PM365/reports%20(1)_G0cISZOj08.gif?updatedAt=1720501713282'},
    { id: 3, title: '360-Degree', Heading: 'Comprehensive 360-Degree Feedback for Ongoing Improvement', 
      content:  (
        <ul>
         <li>
         HR performance management software enables tracking of employee progress and facilitates the exchange of constructive and productive feedback among peers, managers, and other stakeholders.
         </li>
       <li>
       This creates a continuous feedback loop within the appraisal cycle and supports spontaneous ad hoc feedback scenarios.
       </li>
       <li>
       Anonymous feedback options encourage honest input and protect the confidentiality of reviewers, promoting more accurate and actionable insights.
       </li>
         
        </ul>
      ), image: 'https://ik.imagekit.io/zn4au2jftpm5/Apps365/GIF/PM365/feedback_98syly-MO.gif?updatedAt=1720501713725' },
    {
        id: 4, title: 'Dashboards', Heading: 'Performance Dashboards: SharePoint Employee Management Tool', content:(
          <ul>
            <li>
            Enhance workflows with our employee performance evaluation software—automate reminders, generate reports, and send notifications via email or Teams.
            </li>
            <li>
            The dashboard offers a quick overview of appraisal status, minimizing coordination time and letting HR365 handle follow-ups.
            </li>
            <li>
            Customizable widgets and filters on the dashboard allow users to focus on specific performance metrics and trends.
            </li>
            <li>
            Real-time data synchronization ensures that all dashboard information is current and reflects the latest updates and evaluations.
            </li>
          </ul>
        ),
        image: 'https://ik.imagekit.io/zn4au2jftpm5/Apps365/GIF/PM365/dashboard_WeNdVZqIV.gif?updatedAt=1720501713940'
    },
  
];
  const planData = [
    {
      name: "Standard",
      price: "$79",
      sup: "99",
      text: "Standard Plan Features",
      textY: "per month, billed yearly",
      features: [
        "10 users",
        "2 support tickets per annum",
        "Free updates via MS store",
        "Integrates with Office 365",
        "SharePoint app only",
        "Data stays in SharePoint",
        "Multiple roles",
      ],
    },
    {
      name: "Plus",
      price: "$149",
      sup: "99",
      textY: "per month, billed yearly",
      text: "Everything in Standard and...",
      features: [
        "25 users",
        "4 support tickets per annum",
        "Free updates with support",
        "KRA Categories",
        "Custom Logo",
        "5 Point rating scale",
        "Notifications",
        "Multiple departments & groups",
        "Multiple appraisal cycles",
      ],
    },
    {
      name: "Premium",
      price: "$249",
      sup: "99",
      textY: "per month, billed yearly",
      text: "Everything in Plus, and...",
      features: [
        "40 users",
        "Additional users at $1.5/user",
        "Unlimited support tickets",
        "Microsoft Teams App",
        "Self-Review",
        "KRA bank",
        "KRA templates",
        "Weighted rating option",
        "Dashboard - Performance Analytics",
        "Rating to verbatims",
        "360 degree feedback module",
        "1:1 meeting tracker Module",
        "Smart notifications",
      ],
    },
    {
      name: "Enterprise",
      price: "$329",
      sup: "99",
      textY: "per month, billed yearly",
      text: "Everything in Premium and...",
      features: [
        "50 Users",
        "Additional users at $2/user",
        "Multiple Reviewers",
        "9 point enterprise rating scale",
        "Multiple rating scales options",
        "Collaboration timelines",
        "Dashboard - Org analytics",
        "1:1 Meetings on Calendar",
        "Customizable email notifications",
        "Customize with Power Automate",
        "Power BI & Power Apps Integration",
        "Dedicated Account Manager",
        "Integration with KRAs",
        "One free customization*",
      ],
    },
  ];
  const accordionItems = [
    {
      title: `1. What is an employee performance tracker, and why is it important?`,
      content:
        `An employee performance tracker is a tool within performance management software that monitors and records employee progress against set goals and objectives. It is crucial for identifying strengths, areas for improvement, and ensuring that employees are aligned with company goals.`,
    },
    {
      title: `2. How do I choose the best performance management software for my business?`,
      content: `To choose the best performance management software, consider factors like ease of use, scalability, integration capabilities, and customer support. It should align with your business needs and help in achieving your performance management goals effectively.`,
    },
    {
      title:
        `3. Can performance management software integrate with our existing HR systems?`,
      content:
       `Yes, most performance management software is designed to integrate seamlessly with existing HR systems, including payroll, HRIS, and learning management systems. This integration ensures a unified approach to managing employee performance across the organization.`,
    },
    {
      title: `4. Can I install multiple instances of Employee Performance Management 365?`,
      content: `Yes, you can install multiple instances of the application as long as it is in one Microsoft 365 tenant and total number of users of all the instances installed remain within the user limit as per your plan or license you purchased.`,
    },
    {
      title: `5. How ${AppName}'s Performance Management System differentiate?`,
      content: `Employee Performance Management Tools or employee feedback app enable clients to conduct a multi-dimensional assessment of their employees’ abilities, behavioural competencies, and performance. The analytics system will provide managers with the data, expertise, and tools they need to conduct a more constructive evaluation of their team.`,
    }
  ];
  const [isLightboxOpen, setLightboxOpen] = React.useState(false);
  const [selectedImageUrl, setSelectedImageUrl] = React.useState("");

  const openLightbox = (imageUrl) => {
    setSelectedImageUrl(imageUrl);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
  };

  return (
    <>
    <HelmetProvider>
                <Helmet>
                    <title>Boost Productivity with HR365 Performance Management Software</title>
                    <meta name="description" content="Empower employees, track goals, and drive growth with HR365’s 360° feedback system. Integrated with Microsoft 365, it’s the key to unlocking peak performance." />
                </Helmet>
       <ScrollToTop/>
    <Header/>
      <div className="HR_panel">
        <div className="HR_FlexCenterSpcBetween HR_ParentSection1">
          <div className="HR_Section1LeftSide">
            <h1 className="HR_primaryclr  ">
            Help your employees thrive with tailored development and real-time insights.
            </h1>
            <p className="HR_textclr">
            Boost productivity and engagement with HR365’s Performance Management Software for Microsoft 365. Streamline goal-setting, continuous feedback, and 360-degree reviews to unlock your team’s true potential. Identify top talent, close skill gaps, and drive outstanding business results—all in one powerful solution.
            </p>
            
          </div>
          <div className="HR_Section1RightSide">
            {/* <img alt="MainImage" src={ImageSction1} loading="lazy" /> */}
            <img className="BannerImageStyles" alt="MainImage" src="https://ik.imagekit.io/zn4au2jftpm5/Apps365/productPAGES/performanceManagement_IMgiRRG_qJ.png?updatedAt=1718793675682"loading="lazy" />
          </div>
        </div>
      </div>
    {/* <GetOfferPage Type="Image"/> */}
      <div>
                <G2Badge
                 heading="Now Performance Management 365 App is Available on Microsoft Teams"
                 imageUrl="https://ik.imagekit.io/zn4au2jftpm5/Apps365/Teams/PM365_nLChfGaEs.png?updatedAt=1720507129448"  />
            </div>
            <div id="features" className="IdChanges"></div>
            <div >
            <TabsWithImage tabs={tabs}/>
        </div>
        <div>
                <Availability/>
            </div>
            <div id="client" className="IdChanges"></div>
            <div className='HR_whitesection' >
                <h2 className='HR_heading HR_MT' >Our Clients
                </h2>

                <div className='HR_MT'>
                    <OurClients />
                </div>
            </div>
            <div id="integration" className="IdChangesIntegration"></div>
            <div className='integartionPT'>
           <Integration appName={"Performance Management 365"} />

           </div>
   
           <div  className="IdChangesIntegration"></div>
            <h2 className='HR_heading HR_MT' id="pricing" >Performance Management 365 Plans
            </h2>
     

      <div className="HR_whitesection">
      <div className="HR_MT">
          <div>
            <Plans plans={planData} />
          </div>
        </div>
       
        <div className='AzureLogoText'>
                        <a href='https://appsource.microsoft.com/en-us/product/web-apps/hr365bizapps365.epm365p5a001?tab=PlansAndPrice' target='_blank'>
                            <img className='AzureImage' src='https://ik.imagekit.io/zn4au2jftpm5/Azure_dQeGdjXNs_cWe2-VVEx.jpeg?updatedAt=1736158710879' alt='Azure' />
                        </a>
                        <p className='AzureText'>
                            Using Azure? now you can <a href='https://appsource.microsoft.com/en-us/product/web-apps/hr365bizapps365.epm365p5a001?tab=PlansAndPrice' target='_blank'>
                                buy
                            </a>
                            on Azure Marketplace or using your enterprise agreement
                        </p>
                    </div>
        <SpecailButtons userCount={"500+"} />
        <h3 className='HR_heading HR_MT'>Get started with 14 days risk free trial. <br />No credit card details required
                </h3>
                <div className='Hr_flex HR_MT'>
                    <DemoButtons />
                </div>
            </div>
            <span className='privacytext'>*One Free Customization (upto 4 hours)</span>
            <div id="faq" className="IdChangesIntegration"></div>
            <div className='HR_FAQ' >

                <div>
                    <h3 className='HR_faq'>Frequently Asked Questions</h3>
                    <Accordion items={accordionItems} />
                </div>


            </div>
   <Footer/>
   </HelmetProvider>
    </>
  );
}
export default PM365;
