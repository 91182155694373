import * as React from 'react';
import DemoButtons from './Utiilities/DemoButtons';
import ImageSction1 from "../Assests/Images/Ed365/ImageSection1.png";
import Plans from './Utiilities/Plans';
import Accordion from './Utiilities/AccordionItem';
import SpecailButtons from './Utiilities/SpecialButtons';
import Header from './Header and Footer/Header';
import Footer from './Header and Footer/Footer';
import ScrollToTop from './Utiilities/ScrolltoTop';
import G2Badge from './Utiilities/G2Badge';
import TabsWithImage from './Utiilities/TabsWithImage';
import Availability from './Utiilities/Availability';
import OurClients from './Utiilities/OurClients';
import Integration from './Utiilities/Integration';
import { MyContext } from '../App';
import { HelmetProvider, Helmet } from "react-helmet-async";
import { useEffect, useState } from 'react';
import GetOfferPage from './Utiilities/GetOfferPage';
// import { mouseflow} from "react-mouseflow";
function AM1() {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    
        React.useEffect(() => {
            const handleResize = () => {
                setIsMobile(window.innerWidth <= 767);
            };
    
            window.addEventListener('resize', handleResize);
    
            return () => {
                window.removeEventListener('resize', handleResize);
            };
    }, []);
    // React.useEffect(() => {
    //     mouseflow.initialize("d74e23c0-0490-4d54-925d-b4ea1ae7151a");
    //     }, []);
    const AppName = React.useContext(MyContext)
    const planData = [
        {
            name: 'Standard',
            price: '$129',
            sup: '99',
            text: 'Standard Plan Features',
            textY: 'per month, billed yearly',
            features: ['50 users','2 support tickets per annum','Free updates via MS store','Assign Assets','Return Assets','Track assets','Asset life cycle history','Vendor tagging','Asset Status','Categories & Sub categories'],

        },
        {
            name: 'Plus',
            price: '$149',
            sup: '99',
            textY: 'per month, billed yearly',
            text: 'Everything in Standard and...',
            features: ['50 users ','Unlimited support','Assets Dashboard','Configure asset attributes','Custom Asset status','Custom Asset types','Asset assign notifications','Asset return notifications','Bar & QR code generator'],
        },
        {
            name: 'Premium',
            price: '$199',
            sup: '99',
            textY: 'per month, billed yearly',
            text: 'Everything in Plus, and...',
            features: ['100 users ','Add-on $25 for every 50 users','Tag Projects','Free updates with support','Custom email templates','MS Intune Integration','Depreciation','Book Assets','Bar & QR code scanner','Export QR Codes and Barcodes','Asset expiry reminders','Return date reminders','Add custom columns'],
        },
        {
            name: 'Enterprise',
            price: '$249',
            sup: '99',
            textY: 'per month, billed yearly',
            text: 'Everything in Premium and...',
            features: ['100 users','Add-on $25 for every 50 users','Sync M365 Licenses​','Asset approval workflow​','Digitally Signed Acknowledgment','Desktop Central Integration​','SCCM Integration​','Maintenance Module','Software Module​','Export Audit Logs​','API connectivity​','Customize with Power Automate','Power BI & Power Apps Integration​','Location based roles','Custom forms','Dedicated Account Manager','One free customization*'],
        },
    ];;
    const accordionItems = [
        { title: 'Does SharePoint have asset management?', content: `Asset Management System (AMS) is your trusted companion for effortlessly tracking and managing all your assets. Built on Microsoft 365 and SharePoint, it combines simplicity with innovation, making asset tracking a breeze. Asset Management 365, a Microsoft-certified, cloud-powered solution infused with AI, ensures you stay in control of your valuable assets with ease and confidence.` },
        { title: 'How to create an asset library in SharePoint?', content: (<ul>
            Creating an Asset Library in SharePoint is simple:
            <li>
            	Click + New  App.
            </li>
            <li>
            In the classic experience, go to Lists, Libraries, and Other Apps, then select Add an App
            </li>
            Note: You'll need permission to create lists. If you do not have the required permissions, the + New menu or Add an App option won’t be visible.
        </ul>
            ) },
            { title: 'Where is Site Assets in SharePoint?', content: (<ul>
           To find Site Assets in SharePoint assets:
                 <li>
                 Go to Your Site and click the Settings gear icon.
                 </li>
                 <li>
                 Select Site Contents from the menu.
                 </li>
                 <li>Locate Site Assets in the list.</li>
                 <p>Note: If you do not find it, you may need permissions or help from your SharePoint admin.</p>
              </ul>
                  ) },
            { title: 'What is the difference between SharePoint Asset Management and DAM?', content: (<ul>
              SharePoint and Digital Asset Management (DAM) serve different purposes:
                <li>
                	<b>SharePoint Asset Management: </b>An easy collaboration tool for managing documents, workflows, and team projects. Ideal for general file sharing and productivity.
                </li>
               <li>
            <b>   	DAM:</b> A specialized system for organizing and managing media assets like images and videos, with features like tagging and rights management. Perfect for marketing and creative teams.
               </li>
               <p>In short, SharePoint asset management supports team collaboration, while DAM focuses on managing rich media content.</p>
            </ul>
                ) },
                


    ];
    // const [isLightboxOpen, setLightboxOpen] = React.useState(false);
    // const [selectedImageUrl, setSelectedImageUrl] = React.useState('');
  
    // const openLightbox = (imageUrl) => {
    //   setSelectedImageUrl(imageUrl);
    //   setLightboxOpen(true);
    // };
  
    // const closeLightbox = () => {
    //   setLightboxOpen(false);
    // };
    const tabs = [
        { id: 1, title: 'Integrated with Azure', Heading: 'IT asset management software integrated with Azure', content: (
            <ul>
                <li>
                Microsoft Asset Management App integrates seamlessly with Microsoft Intune for dynamic asset retrieval and effective management of software licenses, service agreements, and Office 365 subscriptions in SharePoint.
                </li>
                <li>
                It supports flexible asset allocation across users, groups, and locations, optimizing resource use.
                </li>
                <li>
                The robust workflow engine automates renewals, enhancing efficiency, while the Azure Asset Management App continuously updates the Azure asset inventory for improved management.
                </li>
            </ul>
        ), image: 'https://ik.imagekit.io/zn4au2jftpm5/Apps365/GIF/AM365/azure_Faooyo8YDz.gif?updatedAt=1720174577090' },
        { id: 2, title: ' Featuring Audits', Heading: ' IT and Physical asset management solutions featuring Audits', content:(
            <ul>
                <li>Online asset management software simplifies license management and reporting, moving away from labor-intensive Excel sheets.</li>
                <li>
                It features an audit function to track an asset’s entire lifecycle, including assignment dates and usage patterns.
                </li>
                <li>
                You can easily audit contracts, software licenses, and hardware configurations, ensuring compliance and identifying underutilized equipment.
                </li>
                <li>
                For detailed insights and a personalized demo, contact us.
                </li>
            </ul>
        ), image: 'https://ik.imagekit.io/zn4au2jftpm5/Apps365/GIF/AM365/featuringAudits_R3se0Mc6pO.gif?updatedAt=1720174577072'},
        { id: 3, title: 'Barcode Scanning', Heading: 'Barcode Scanning, Image Capture, and Document Management with SharePoint IT Asset Management solutions', content: (
            <ul>
                <li>
                ITAM software generates barcodes and QR codes for easy tracking through the asset monitoring app.
                </li>
                <li>
                Asset details can be verified with mobile devices or barcode scanners, improving efficiency.
                </li>
                <li>
                The tool also links comprehensive documentation to each asset, including manuals, support contacts, and guidelines, ensuring easy access to relevant information.
                </li>
            </ul>
        ), image: 'https://ik.imagekit.io/zn4au2jftpm5/Apps365/GIF/AM365/barcode_RPpEWhHap1.gif?updatedAt=1720174576528' },
        { id: 4, title: 'Reports', Heading: 'Asset Management Application: In-Depth Reports', content: (
            <ul>
               <li>
               Seamless data export to Excel for your convenience.
               </li>
               <li>
               Effortless customization of column order to match your preferences.
               </li>
               <li>
               Simplified data presentation by eliminating unnecessary columns.
               </li>
               <li>
               Addition of custom columns to incorporate personalized insights.
               </li>
               <li>
               Enhanced data exploration with advanced search capabilities and filters.
               </li>
               <li>
               Automated report generation schedules for regular updates.
               </li>
               <li>
               Integration with other software tools for comprehensive data analysis
               </li>
               <li>
               Real-time data synchronization for up-to-date information.
               </li>
            </ul>
        ), image: 'https://ik.imagekit.io/zn4au2jftpm5/Apps365/GIF/AM365/barcode_RPpEWhHap1.gif?updatedAt=1720174576528' },
       
       
    ];

    return (
        <> 
        <HelmetProvider>
                <Helmet>
                    <title>HR365 Asset Management Software - Simplify Asset Tracking & Lifecycle Management</title>
                    <meta name="description" content="Track, manage, and optimize your assets with HR365. From barcoding to audits, ensure full visibility, control, and seamless renewals in just a few clicks." />
                </Helmet>
             <ScrollToTop/>
        <Header/>
            <div className='HR_panel'>
                <div className="HR_FlexCenterSpcBetween HR_ParentSection1" >
                    <div className='HR_Section1LeftSide'>
                        <h1 className='HR_primaryclr  ' >Simplify Asset Management—Because Every Asset Deserves Care</h1>
                        <p className='HR_textclr'>Experience seamless asset tracking and management with HR365. From barcoding to lifecycle insights, gain complete control and maximize the value of your assets effortlessly.</p>
                       
                    </div>
                    <div className='HR_Section1RightSide'>
                     
                        <img className='BannerImageStyles' alt='MainImage' src="https://ik.imagekit.io/zn4au2jftpm5/Apps365/productPAGES/AssetManagement.png?updatedAt=1718777094204"   />
                    </div>
                </div>
            </div>
          {/* <GetOfferPage Type="Image"/> */}
            <div>
                <G2Badge
                 heading="Now Asset 365 App is Available on Microsoft Teams"
                 imageUrl= "https://ik.imagekit.io/zn4au2jftpm5/Apps365/Teams/image%20(8)_Do7TgjeZV9.png?updatedAt=1725450644578"  />
            </div>
            <div id="features" className="IdChanges"></div>
            <div  >
            <TabsWithImage tabs={tabs}/>
        </div>
        <div>
                <Availability/>
            </div>
            <div id="client" className="IdChanges"></div>
            <div className='HR_whitesection' >
                <h2 className='HR_heading HR_MT' >Our Clients
                </h2>

                <div className='HR_MT.' id='client'>
                    <OurClients />
                </div>
            </div>
            <div id="integration" className="IdChangesIntegration"></div>
            <div  className='integartionPT'>
           <Integration appName={"Asset Management 365"} />

           </div>
           <div  className="IdChangesIntegration"></div>

           <div  className='integartionPT'></div>
            <div className='HR_whitesection'>

            <h2 className='HR_heading HR_MT' id="pricing">Asset Management 365 Plans
            </h2>
          <div>
            <Plans plans={planData} appName={"Asset Management 365"}/>
          </div>
        
       
        <SpecailButtons userCount={"500+"} />
        <h3 className='HR_heading HR_MT'>Get started with 14 days risk free trial. <br />No credit card details required
                </h3>
                <div className='Hr_flex HR_MT'>
                    <DemoButtons />
                </div>
            </div>
            <span className='privacytext'>*One Free Customization (upto 4 hours)</span>
            <div id="faq" className="IdChangesIntegration"></div>
            <div className='HR_FAQ' id="faq">

                <div>
                    <h3 className='HR_faq'>Frequently Asked Questions</h3>
                    <Accordion items={accordionItems} />
                </div>


            </div>

        <Footer/>
       </HelmetProvider>
        </>
    )
}
export default AM1;