import * as React from 'react';
import DemoButtons from './Utiilities/DemoButtons';
// import ImageSction1 from "../Assests/Images/Ed365/ImageSction1.png"
import ImageSlider from './Utiilities/ImageSlider';
import Plans from './Utiilities/Plans';
import Accordion from './Utiilities/AccordionItem';
import SpecailButtons from './Utiilities/SpecialButtons';
import Header from './Header and Footer/Header';
import Footer from './Header and Footer/Footer';
import G2Badge from './Utiilities/G2Badge';
import 'swiper/css';
import 'swiper/css/pagination';
import TabsWithImage from './Utiilities/TabsWithImage';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import Integration from './Utiilities/Integration';
import Availability from './Utiilities/Availability';
import OurClients from './Utiilities/OurClients';
import { MyContext } from '../App';
import { HelmetProvider, Helmet } from "react-helmet-async";
import { useEffect, useState } from 'react';
import OfferPopup from './Utiilities/Offerpopup';
import GetOfferPage from './Utiilities/GetOfferPage';
const ImageSction1 = require('../Assests/Images/Ed365/ImageSection1.png');
function EO365() {
    const AppName = React.useContext(MyContext)
    const [showOfferPopup, setShowOfferPopup] = useState(false);

    const handleImageClick = () => {
        setShowOfferPopup(true);
    };

    const closeOfferPopup = () => {
        setShowOfferPopup(false);
    };

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    
    React.useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 767);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
}, []);

    const dynamicImages = [
        'https://ik.imagekit.io/zn4au2jftpm5/hr365/LOGO/Client%20logos1_RPf_AeXggA.png?updatedAt=1700627745162',
        'https://ik.imagekit.io/zn4au2jftpm5/hr365/LOGO/Client%20logos2_fZX_JD70Nu.png?updatedAt=1700627744112',

    ];
    const tabs = [
        { id: 1, title: 'Hire employee online', Heading: 'Streamline Hiring with Our Employee Onboarding Software', content:(
            <ul>
               <li>
               Employee Onboarding app, integrated with Microsoft, centralizes the onboarding process. It streamlines job offer approvals, digital form processing, and credential verification.
               </li>
               <li>
               Integration with Microsoft Teams ensures timely communication, while the SharePoint employee onboarding template uses automated workflows to send reminders and alerts to IT, admin, hiring managers, and HR, simplifying onboarding for all stakeholders.
               </li>
                </ul>

        ), image: 'https://ik.imagekit.io/zn4au2jftpm5/Apps365/GIF/Employee-Directory/HireEmpOnline_UiSuX5ANR.gif?updatedAt=1720179043756' },
        { id: 2, title: 'Onboarding Platform', Heading: 'Upgrade your onboarding process using our state-of-the-art platform', content: (
            <ul>
                <li>
                Seamless integration with Office 365 automates the update of new hire data across all {AppName} dashboard applications, including leave management, expense tracking, and asset management.
                </li>
                <li>
                The platform optimizes organizational efficiency by ensuring consistent and timely updates throughout the onboarding process.
                </li>
                <li>
                Interactive onboarding checklists guide new hires through each step of the process, ensuring a smooth transition.
                </li>
                <li>
                Real-time notifications and alerts keep both new hires and HR teams informed of important milestones and tasks.
                </li>
            </ul>
        ), image: 'https://ik.imagekit.io/zn4au2jftpm5/Apps365/GIF/Employee-Directory/OnBoardingPlaform_LrTLMDLPn.gif?updatedAt=1720179044499' },
        { id: 3, title: ' Self-service portal', Heading: 'Streamline Onboarding with Our SharePoint Self-Service Portal', content: (
            <ul>
                <li>
                SharePoint employee onboarding software ensures a seamless online transition for new hires, handling tasks like offer letter acceptance and form completion efficiently.
                </li>
                <li>
                It also introduces new employees to managers, teams, and company culture, fostering a strong connection from day one.
                </li>
                <li>
                Automated reminders and task tracking ensure new hires complete all onboarding steps promptly and accurately.
                </li>
                <li>
                Customizable onboarding workflows tailor the experience to specific roles or departments, enhancing relevance and engagement.
                </li>
            </ul>
        ), image: 'https://ik.imagekit.io/zn4au2jftpm5/Apps365/GIF/Employee-Directory/Selfserviceportal_l0zIfu3_7h.gif?updatedAt=1720179043999' },
        {
            id: 4, title: ' HR & Employee Checklists ', Heading: 'Boost HR Efficiency with Our SharePoint Onboarding App', content: (
                <ul>
                    <li>
                    Onboarding app, developed with PowerApps, streamlines HR workflows and ensures efficient onboarding.
                    </li>
                    <li>
                    It provides employees with a detailed checklist for a smooth, compliant experience, while customizing workflows, managing documents, and facilitating electronic signatures and IT setup.
                    </li>
                    <li>
                    Real-time analytics and reporting tools offer insights into onboarding progress and efficiency, helping to identify areas for improvement.
                    </li>
                </ul>
            ),
            image: 'https://ik.imagekit.io/zn4au2jftpm5/Apps365/GIF/Employee-Directory/Hr&EmpChecklist_XkaypoFOMG.gif?updatedAt=1720179044295'
        },
        {
            id: 5, title: ' Integration and Reporting', Heading: 'Optimize integration and reporting with our Employee Onboarding ap', content: (
                <ul>
                    <li>
                    Microsoft team's employee onboarding solution integrates seamlessly with various business applications and payroll software through APIs.
                    </li>
                    <li>
                    It allows for customized workflows using our HR onboarding system and integrates with Microsoft Power Automate and Power Apps across the organization.
                    </li>
                    <li>
                    Advanced reporting features provide comprehensive analytics on onboarding metrics and trends for informed decision-making.
                    </li>
                    <li>
                    Real-time synchronization with payroll and business applications ensures accurate and timely processing of employee data.
                    </li>
                </ul>
            ),
            image: 'https://ik.imagekit.io/zn4au2jftpm5/Apps365/GIF/Employee-Directory/reports_qeHCGbZhn.gif?updatedAt=1720179043567p'
        },
    ];
    const planData = [
        {
            name: 'Standard',
            price: '$49',
            sup: '99',
            text: 'Standard Plan Features',
            textY: 'per month, billed yearly',
            features: ['10 users', '2 support tickets per annum', 'Free updates via MS store', 'Integrates with Office 365', 'SharePoint app only', 'Data stays in SharePoint', 'Mobile responsive', 'Multiple roles', 'Onboarding module with template', 'Standard notifications'],
        },
        {
            name: 'Plus',
            price: '$149',
            sup: '99',
            textY: 'per month, billed yearly',
            text: 'Everything in Standard and...',
            features: ['25 users', '4 support tickets per annum', 'Free updates with support', 'Theme customization', 'Digital joining forms', 'Employee record repository', 'Tasks & checklist for HR', 'Company policy documents', 'Document repository'],
        },
        {
            name: 'Premium',
            price: '$199',
            sup: '99',
            textY: 'per month, billed yearly',
            text: 'Everything in Plus, and...',
            features: ['40 users', 'Additional users at $1/user', 'Microsoft Teams App', 'Additional user properties', 'Custom fields in joining forms', 'Employee self service portal', 'Dashboard - Onboarding ', 'Outlook calendar integration', 'Tasks & checklist for various roles', 'Task tracking, status and reminders ', 'Employee self-service portal  '],
        },
        {
            name: 'Enterprise',
            price: '$299',
            sup: '99',
            textY: 'per month, billed yearly',
            text: 'Everything in Premium and...',
            features: ['50 users', 'Additional users at $1/user', 'Dashboard - Organization analytics', 'Auto user creation in Office 365 ', ' Auto add employee in MS Teams', 'Auto add employee in Office 365 Groups', 'Pre-onboarding module', 'MS forms integration', 'Adobe Sign / DocuSign Integration', 'Multiple reports', ' Smart and customizable notifications', ' Customize with Power Automate.', 'API connectivity', 'Connect with Power BI', 'Dedicated Account Manager', 'One free customization*'],
        },
    ];
    const accordionItems = [
        { title: `1. How does new hire onboarding software improve the onboarding process?`, content: `New hire onboarding software enhances the onboarding experience by automating tasks, providing a centralized platform for all onboarding activities, and ensuring that new employees have access to necessary resources from day one. It also helps track progress and compliance, making the onboarding process more efficient and effective.` },
        {
            title: '2.Can hiring and onboarding software integrate with our existing HR tools?', content: `Yes, most hiring and onboarding software solutions are designed to integrate with existing HR tools such as payroll systems, benefits administration, and performance management platforms. This ensures a seamless experience from recruitment to onboarding and beyond.` },
        {
            title: '3. What benefits does a SharePoint onboarding app offer?',
            content: `A SharePoint onboarding app leverages the power of SharePoint to create a customized and collaborative onboarding experience. It allows new hires to access company resources, complete training modules, and connect with team members, all within the familiar SharePoint environment.`
        }, {
            title: '4. How does a new hire onboarding platform ensure compliance?',
            content: `A new hire onboarding platform helps ensure compliance by providing a structured and consistent onboarding process. It includes tools for tracking document submission,

completion of mandatory training, and adherence to company policies, reducing the risk of non-compliance.`
        }, {
            title: `5. What is employee onboarding process and what are 5 C's in Onboarding?`,
            content: `How do you count user license in employee onboarding 365 application. Any user who is onboarded using application, via bulk upload, HR, admin, hiring manager or any other roles who interact with application for approvals or any other activities, are counted towards user licenses. This application provides self-service for the onboarded employees, maintain their documents digitally and send reminders based on selected tasks and reminders.`
        }, {
            title: `6. Can I install multiple instances of Employee onboarding 365?`,
            content: `Yes, you can install multiple instances of the application as long as it is in one Microsoft 365 tenant and total number of users of all the instances installed remain within the user limit as per your plan or license you purchased.`
        }, {
            title: "7. A good employee onboarding solutions/ employee onboarding process flow",
            content: `First stepping stone: Releasing of offer The employee onboarding process starts soon after the recruitment process is completed. Once a candidate is selected, an HR team shares a warm welcome email with a few essential documents like the offer letter, new hire welcome packet, links to fill out digital employee onboarding forms, and policy documents. Reaction from the New hire: Offer Acceptance or denial Once the new hire accepts or denies the offer, as the best practice the organizations should schedule a quick call to review the digital employee onboarding forms, benefits, and policies, and set expectations. Keeping the new hires engaged will affirm their choice to accept the offer.

Upon receiving positive feedback on the offer, it is important to kick-start the employee orientation. Waiting period: Critical period It is very important to know if the new hire just accepted the offer, but it doesn’t promise that they’ll turn up for the date of joining. During the waiting period, the employee might be open to offers from other potential employers as well. So, it is very crucial for the organization to build a good rapport with the new hire. Let the new hire know they’re valued. It is essential to have a plan during their waiting period while the employee onboarding process.

Last milestone: The day of joining On the first day, most new hires have mixed emotions. They feel anxious, happy, excited, and nervous at the same time. So, the primary duty of HR managers is to ensure that the new hires feel welcome and comfortable. Having a handy employee onboarding checklist will relieve the stress of HR staff. Here are a few

things to do before the day of joining: Keep the orientation schedule ready Assign IT assets (workstation, email access, etc.) Obtain necessary office supplies (furniture, keys, access card, etc.)

Set up a salary account Assign a mentor or go-to-person who can help the employee settle down Coordinating with other departments It is essential to coordinate with key stakeholders (co-workers and managers) and notify them of the start date of the new hire via the employee onboarding platform.

Training and orientation sessions give the new hire an overview of the organization’s culture and an insight into company OKR. This phase offers new hires relevant information about the teams within the company, team processes, and company policies. This is the right time to set role-based goals and objectives for the next 30/ 60/ 90 days to show the new hires what they need to focus on. First bridge: The first quarter

The major objective of this period is to review the expectations of the organizations and the employee and ensure they match. This phase needs to be filled with active dialogues about the progress and continued efforts of the new hire in becoming an integral part of the organization.`
        },
        {
            title: `8. Reinventing employee onboarding through automation/ digital employee onboarding`,
            content: `No matter how big the organization are, The HR leaders tend to have too much on their plates. They spend enough time scavenging for the right resources, and by the time the onboarding starts, they are downright exhausted. Rather than adding to their fatigue by forcing them to do things the old-fashioned way, automating with employee onboarding software or employee onboarding tools reduces their workload by a considerable amount. Using employee onboarding software, can ditch the onboarding checklist and forms and eliminate manual dependency in the employee onboarding process. With a digital onboarding experience using online employee onboarding app at work, they don’t have to chase after new hires and managers to complete the tasks assigned to them.`
        },

    ];


    return (
        <>
        <HelmetProvider>
                <Helmet>
                    <title>Employee Onboarding Software That Makes First Days Amazing!</title>
                    <meta name="description" content="HR365 takes the hassle out of onboarding with an easy-to-use, automated solution. From digital forms to instant account setup, give your new hires the best start – effortlessly!" />
                </Helmet>
            {/* <ScrollToTop/> */}
            <Header />
            <div className='HR_panel'>
                <div className="HR_FlexCenterSpcBetween HR_ParentSection1" >

                    <div className='HR_Section1LeftSide'>
                        <h1 className='HR_primaryclr '>Say Goodbye to Paperwork, Say Hello to Seamless Onboarding!</h1>
                        <p className='HR_textclr'>
                            {`Simplify onboarding with a seamless, automated process that saves time and effort. HR365 ensures every step, from digital forms to task management, is hassle-free, making a lasting impression on your new hires.`}</p>
                        <h3 className='HR_primaryclr '>Get started with 14 days risk free trial.</h3>
                        <h3 className='HR_primaryclr '>No credit card details required</h3>
                        <div className='HR_OuterDemoButtons'>
                            <DemoButtons />
                        </div>
                    </div>
                    <div className='HR_Section1RightSide'>
                        {/* <img alt='MainImage' src={ImageSction1} loading="lazy" /> */}
                        <img className='BannerImageStyles' alt='MainImage' src="https://ik.imagekit.io/zn4au2jftpm5/Apps365/productPAGES/EmployeeOnboarding.png?updatedAt=1718777070984" loading="lazy" />
                    </div>
                </div>
            </div>
           

        {/* <GetOfferPage Type="Image"/> */}
            <div>
                <G2Badge
                    heading="Now Employee Onboarding 365 App is Available on Microsoft Teams"
                    imageUrl="https://ik.imagekit.io/zn4au2jftpm5/image%20(1)_arH6NTgsR.png?updatedAt=1724915466230"
                />
            </div>
            <div id="features" className="IdChanges"></div>
            <div >
                <TabsWithImage tabs={tabs} />
            </div>
            <div>
                <Availability />
            </div>
            <div id="client" className="IdChanges"></div>

            <div className='HR_whitesection'>
                <h2 className='HR_heading HR_MT' >Our Clients
                </h2>
                <div className='HR_MT'>
                    <OurClients />
                </div>
                <div id="integration" className="IdChangesIntegration"></div>
                <div className='integartionPT'>
                    <Integration appName={"Employee Onboarding 365"} />
                </div>
                <div className='Hr_flex'>
                    <DemoButtons />
                </div>
                <div  className="IdChangesIntegration"></div>
                <h2 className='HR_heading HR_MT' id="pricing">Employee Onboarding 365 Plans
                </h2>
                <div className='HR_MT'>
                    <div>
                        <Plans plans={planData} />
                    </div>
                </div>
                <div className='AzureLogoText'>
                        <a href='https://appsource.microsoft.com/en-us/product/web-apps/hr365bizapps365.eo365p5a001?tab=PlansAndPrice' target='_blank'>
                            <img className='AzureImage' src='https://ik.imagekit.io/zn4au2jftpm5/Azure_dQeGdjXNs_cWe2-VVEx.jpeg?updatedAt=1736158710879' alt='Azure' />
                        </a>
                        <p className='AzureText'>
                            Using Azure? now you can <a href='https://appsource.microsoft.com/en-us/product/web-apps/hr365bizapps365.eo365p5a001?tab=PlansAndPrice' target='_blank'>
                                buy
                            </a>
                            on Azure Marketplace or using your enterprise agreement
                        </p>
                    </div>
                <div className='Hr_flex HR_MT'>
                    <DemoButtons />
                </div>
                <SpecailButtons userCount={"500+"}  />
                <h3 className='HR_heading HR_MT'>Get started with 14 days risk free trial. <br />No credit card details required
                </h3>
            </div>
            <span className='privacytext'>*One Free Customization (upto 4 hours)</span>
            <div id="faq" className="IdChangesIntegration"></div>
            <div className='HR_FAQ' >

                <div >
                    <h3 className='HR_faq'>Frequently Asked Questions</h3>
                    <Accordion items={accordionItems} />
                </div>


            </div>

            <Footer /> 
            </HelmetProvider></>
    )
}
export default EO365;