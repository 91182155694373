import * as React from "react";

function OverviewCommon(props) {
  return (
    <>
    <div style={{padding:'3vw'}}>
        <h2>{props.MainHeading || "Experience the Perfect Blend of AI Smarts and Human Care with Help Desk 365"}</h2>
        <p>{props.MainDescription || "Get the best of AI smart and human touch—deliver happiness right out of the box. Our SharePoint ticketing system gets you going fast with simplified workflows that let your employees glide through tasks effortlessly. As well as you can use our Help desk software easily within Microsoft 365 apps like Teams, Outlook, and SharePoint. "}</p>
        <img
              alt={props.MainImageAlt || "Helpdesk 365"}
              src={props.MainImageSrc || "https://ik.imagekit.io/zn4au2jftpm5/hr365/SPFxEDP/site-Helpdesk/HD365%20Teams_bGn7Gudo4J.png?updatedAt=1701258105168"}
              loading="lazy"
            />
    </div>

    </>
  );
}

export default OverviewCommon;
