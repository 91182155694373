import * as React from "react";
// import Link from "@mui/material/Link";
import { Link } from "react-router-dom";
import Footer from "./Header and Footer/Footer";
import Header from "./Header and Footer/Header";
import ScrollToTop from "./Utiilities/ScrolltoTop";
import { MyContext } from "../App";
const ProductsAddon = () => {
    const AppName = React.useContext(MyContext)
    return (
        <>
            <ScrollToTop />
            <Header />
            <div className="HR_panel">
                {/* ED365 */}
                <div>

                    <div className="HR_HomePagesection">
                        <label>
                            <Link className="MB_heading" to="/employee-directory-365">
                                Employee Directory 365
                            </Link>
                        </label>
                        <p className="MB_pera">
                            Office 365 employee directory displays information about employee in
                            the organization in visually manner and allows you to find any user
                            in just fraction of seconds with various filters to select,
                            departments, skills, Job title, manager, location, etc..
                        </p>
                    </div>
                    <ul>
                        <li> <div className="HR_HomePagesection">
                            <label>
                                <Link className="MB_heading" to="/sharepoint-employee-directory">
                                    Employee Directory 365 2
                                </Link>
                            </label>
                            <p className="MB_pera">
                                Office 365 employee directory displays information about employee in
                                the organization in visually manner and allows you to find any user
                                in just fraction of seconds with various filters to select,
                                departments, skills, Job title, manager, location, etc..
                            </p>
                        </div>
                        </li>
                        <li> <div className="HR_HomePagesection">
                            <label>
                                <Link className="MB_heading" to="/sharepoint-staff-directory">
                                    Employee Directory 365 3
                                </Link>
                            </label>
                            <p className="MB_pera">
                                Office 365 employee directory displays information about employee in
                                the organization in visually manner and allows you to find any user
                                in just fraction of seconds with various filters to select,
                                departments, skills, Job title, manager, location, etc..
                            </p>
                        </div>
                        </li>
                        <li> <div className="HR_HomePagesection">
                            <label>
                                <Link className="MB_heading" to="/microsoft-employee-directory-365">
                                    Employee Directory 365 4
                                </Link>
                            </label>
                            <p className="MB_pera">
                                Office 365 employee directory displays information about employee in
                                the organization in visually manner and allows you to find any user
                                in just fraction of seconds with various filters to select,
                                departments, skills, Job title, manager, location, etc..
                            </p>
                        </div>
                        </li>
                        <li> <div className="HR_HomePagesection">
                            <label>
                                <Link className="MB_heading" to="/hr-directory-365">
                                    Employee Directory 365 5
                                </Link>
                            </label>
                            <p className="MB_pera">
                                Office 365 employee directory displays information about employee in
                                the organization in visually manner and allows you to find any user
                                in just fraction of seconds with various filters to select,
                                departments, skills, Job title, manager, location, etc..
                            </p>
                        </div>
                        </li>
                        <li> <div className="HR_HomePagesection">
                            <label>
                                <Link className="MB_heading" to="/employee-directory">
                                    Employee Directory 365 6
                                </Link>
                            </label>
                            <p className="MB_pera">
                                Office 365 employee directory displays information about employee in
                                the organization in visually manner and allows you to find any user
                                in just fraction of seconds with various filters to select,
                                departments, skills, Job title, manager, location, etc..
                            </p>
                        </div>
                        </li>
                    </ul>
                </div>
                {/* HD365  */}
                <div>
                    <div className="HR_HomePagesection">
                        <label>
                            <Link className="MB_heading" to="/helpdesk-365">
                                Helpdesk
                            </Link>
                        </label>
                        <p className="MB_pera">
                            Office 365 employee directory displays information about employee in
                            the organization in visually manner and allows you to find any user
                            in just fraction of seconds with various filters to select,
                            departments, skills, Job title, manager, location, etc..
                        </p>
                    </div>
                    <ul>
                        <li> <div className="HR_HomePagesection">
                            <label>
                                <Link className="MB_heading" to="/microsoft-365-helpdesk-software">
                                    Helpdesk 2
                                </Link>
                            </label>
                            <p className="MB_pera">
                                Office 365 employee directory displays information about employee in
                                the organization in visually manner and allows you to find any user
                                in just fraction of seconds with various filters to select,
                                departments, skills, Job title, manager, location, etc..
                            </p>
                        </div>
                        </li>
                        <li> <div className="HR_HomePagesection">
                            <label>
                                <Link className="MB_heading" to="/microsoft-teams-ticketing-system">
                                    Helpdesk 3
                                </Link>
                            </label>
                            <p className="MB_pera">
                                Office 365 employee directory displays information about employee in
                                the organization in visually manner and allows you to find any user
                                in just fraction of seconds with various filters to select,
                                departments, skills, Job title, manager, location, etc..
                            </p>
                        </div>
                        </li>
                        <li> <div className="HR_HomePagesection">
                            <label>
                                <Link className="MB_heading" to="/sharepoint-ticketing-system">
                                    Helpdesk 4
                                </Link>
                            </label>
                            <p className="MB_pera">
                                Office 365 employee directory displays information about employee in
                                the organization in visually manner and allows you to find any user
                                in just fraction of seconds with various filters to select,
                                departments, skills, Job title, manager, location, etc..
                            </p>

                        </div>
                        </li>
                        <li> <div className="HR_HomePagesection">
                            <label>
                                <Link className="MB_heading" to="/microsoft-ticketing-system">
                                    Helpdesk 5
                                </Link>
                            </label>
                            <p className="MB_pera">
                                Office 365 employee directory displays information about employee in
                                the organization in visually manner and allows you to find any user
                                in just fraction of seconds with various filters to select,
                                departments, skills, Job title, manager, location, etc..
                            </p>

                        </div>
                        </li>
                        <li>
                             <div className="HR_HomePagesection">
                            <label>
                                <Link className="MB_heading" to="/azure-devops-ticketing-system">
                                    Helpdesk 6
                                </Link>
                            </label>
                            <p className="MB_pera">
                                Office 365 employee directory displays information about employee in
                                the organization in visually manner and allows you to find any user
                                in just fraction of seconds with various filters to select,
                                departments, skills, Job title, manager, location, etc..
                            </p>

                        </div>
                        </li>
                        <li>
                             <div className="HR_HomePagesection">
                            <label>
                                <Link className="MB_heading" to="/helpdesk-ticketing-system">
                                    Helpdesk 7
                                </Link>
                            </label>
                            <p className="MB_pera">
                                Office 365 employee directory displays information about employee in
                                the organization in visually manner and allows you to find any user
                                in just fraction of seconds with various filters to select,
                                departments, skills, Job title, manager, location, etc..
                            </p>

                        </div>
                        </li>
                        <li>
                             <div className="HR_HomePagesection">
                            <label>
                                <Link className="MB_heading" to="/microsoft-365-ticketing-system">
                                    Helpdesk 8
                                </Link>
                            </label>
                            <p className="MB_pera">
                                Office 365 employee directory displays information about employee in
                                the organization in visually manner and allows you to find any user
                                in just fraction of seconds with various filters to select,
                                departments, skills, Job title, manager, location, etc..
                            </p>

                        </div>
                        </li>
                        <li>
                             <div className="HR_HomePagesection">
                            <label>
                                <Link className="MB_heading" to="/it-service-ticket-software">
                                    Helpdesk 9
                                </Link>
                            </label>
                            <p className="MB_pera">
                                Office 365 employee directory displays information about employee in
                                the organization in visually manner and allows you to find any user
                                in just fraction of seconds with various filters to select,
                                departments, skills, Job title, manager, location, etc..
                            </p>

                        </div>
                        </li>
                        <li>
                             <div className="HR_HomePagesection">
                            <label>
                                <Link className="MB_heading" to="/it-ticketing-system">
                                    Helpdesk 10
                                </Link>
                            </label>
                            <p className="MB_pera">
                                Office 365 employee directory displays information about employee in
                                the organization in visually manner and allows you to find any user
                                in just fraction of seconds with various filters to select,
                                departments, skills, Job title, manager, location, etc..
                            </p>

                        </div>
                        </li>
                        <li>
                             <div className="HR_HomePagesection">
                            <label>
                                <Link className="MB_heading" to="/sharepoint-helpdesk-software">
                                    Helpdesk 11
                                </Link>
                            </label>
                            <p className="MB_pera">
                                Office 365 employee directory displays information about employee in
                                the organization in visually manner and allows you to find any user
                                in just fraction of seconds with various filters to select,
                                departments, skills, Job title, manager, location, etc..
                            </p>

                        </div>
                        </li>
                        <li>
                             <div className="HR_HomePagesection">
                            <label>
                                <Link className="MB_heading" to="/sharepoint-helpdesk-software">
                                    Helpdesk 12
                                </Link>
                            </label>
                            <p className="MB_pera">
                                Office 365 employee directory displays information about employee in
                                the organization in visually manner and allows you to find any user
                                in just fraction of seconds with various filters to select,
                                departments, skills, Job title, manager, location, etc..
                            </p>

                        </div>
                        </li>
                    </ul>
                </div>
                {/* AM365 */}
                <div>

                    <div className="HR_HomePagesection">
                        <label>
                            <Link className="MB_heading" to="/asset-management-365">
                                Asset Management 365
                            </Link>
                        </label>
                        <p className="MB_pera">
                            Office 365 employee directory displays information about employee in
                            the organization in visually manner and allows you to find any user
                            in just fraction of seconds with various filters to select,
                            departments, skills, Job title, manager, location, etc..
                        </p>
                    </div>
                    <ul>
                        <li> <div className="HR_HomePagesection">
                            <label>
                                <Link className="MB_heading" to="/sharepoint-asset-management-1">
                                    Asset Management 365 2
                                </Link>
                            </label>
                            <p className="MB_pera">
                                Office 365 employee directory displays information about employee in
                                the organization in visually manner and allows you to find any user
                                in just fraction of seconds with various filters to select,
                                departments, skills, Job title, manager, location, etc..
                            </p>
                        </div>
                        </li>
                        <li> <div className="HR_HomePagesection">
                            <label>
                                <Link className="MB_heading" to="/sharepoint-asset-management-2">
                                    Asset Management 365 3
                                </Link>
                            </label>
                            <p className="MB_pera">
                                Office 365 employee directory displays information about employee in
                                the organization in visually manner and allows you to find any user
                                in just fraction of seconds with various filters to select,
                                departments, skills, Job title, manager, location, etc..
                            </p>
                        </div>
                        </li>
                        <li> <div className="HR_HomePagesection">
                            <label>
                                <Link className="MB_heading" to="/sharepoint-asset-management-3">
                                    Asset Management 365 4
                                </Link>
                            </label>
                            <p className="MB_pera">
                                Office 365 employee directory displays information about employee in
                                the organization in visually manner and allows you to find any user
                                in just fraction of seconds with various filters to select,
                                departments, skills, Job title, manager, location, etc..
                            </p>
                        </div>
                        </li>
                    </ul>
                </div>
                {/* TS365  */}
                <div>

                    <div className="HR_HomePagesection">
                        <label>
                            <Link className="MB_heading" to="/sharepoint-timesheet">
                                TimeSheet 365
                            </Link>
                        </label>
                        <p className="MB_pera">
                            Office 365 employee directory displays information about employee in
                            the organization in visually manner and allows you to find any user
                            in just fraction of seconds with various filters to select,
                            departments, skills, Job title, manager, location, etc.
                        </p>
                    </div>
                    <ul>
                        <li> <div className="HR_HomePagesection">
                            <label>
                                <Link className="MB_heading" to="/time-tracking-1">
                                    Timesheet  365 2
                                </Link>
                            </label>
                            <p className="MB_pera">
                                Office 365 employee directory displays information about employee in
                                the organization in visually manner and allows you to find any user
                                in just fraction of seconds with various filters to select,
                                departments, skills, Job title, manager, location, etc..
                            </p>
                        </div>
                        </li>
                        <li> <div className="HR_HomePagesection">
                            <label>
                                <Link className="MB_heading" to="/time-tracking-2">
                                    Timesheet  365 3
                                </Link>
                            </label>
                            <p className="MB_pera">
                                Office 365 employee directory displays information about employee in
                                the organization in visually manner and allows you to find any user
                                in just fraction of seconds with various filters to select,
                                departments, skills, Job title, manager, location, etc..
                            </p>
                        </div>
                        </li>
                        <li> <div className="HR_HomePagesection">
                            <label>
                                <Link className="MB_heading" to="/time-tracking-3">
                                    Timesheet  365 4
                                </Link>
                            </label>
                            <p className="MB_pera">
                                Office 365 employee directory displays information about employee in
                                the organization in visually manner and allows you to find any user
                                in just fraction of seconds with various filters to select,
                                departments, skills, Job title, manager, location, etc..
                            </p>
                        </div>
                        </li>
                    </ul>
                </div>
                <div>

                </div>


            </div>
            <Footer />
        </>
    )
}

export default ProductsAddon
