import * as React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ED365 from "./ED365";
import HD365 from "./HD365";
import Homepage from "./Homepage";
import AM365 from "./AM365";
import TM365 from "./TM365";
import EO365 from "./EO365";
import PM365 from "./PM365";
import Privacypolicy from "./Header and Footer/Privacypolicy";
import Termandcondition from "./Header and Footer/Termandcondition";
import EULA from "./Header and Footer/EULA";
import CLM from "./CLM";
import TS365 from "./TS365";
import Thankyou from "./Thankyou";

import { CelebrateOffer } from "./CelebrateOffer";
import ProductsAddon from "./ProductsAddon";
import ED2 from "./ED2";
import ED3 from "./ED3";
import ED4 from "./ED4";
import HD1 from "./HD1";
import HD2 from "./HD2";
import HD3 from "./HD3";
import TS1 from "./TS1";
import TS2 from "./TS2";
import TS3 from "./TS3";
import AM1 from "./AM1";
import AM2 from "./AM2";
import AM3 from "./AM3";
import ED5 from "./ED5";
import HD4 from "./HD4";
import HD5 from "./HD5";
import HD7 from "./HD7";
import HD8 from "./HD8";
import HD9 from "./HD9";
import HD10 from "./HD10";
import HD11 from "./HD11";
import HD12 from "./HD12";
import ED1 from "./ED1";
import HD365Common from "./GlobalMultiple/HD365Common";
import { Helpdesk1FAQs, Helpdesk1Features, Helpdesk1FirstSection, Helpdesk1MetaTag, Helpdesk1Overview, Helpdesk2FirstSection } from "./GlobalMultiple/GlobalConstants";

function NavBar() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Homepage/>,
      // element: <Homepage />,
    },
    {
      path: "/employee-directory-365",
      element: <ED365 />,
      // loader: "",
      // key: "employee-directory-365",
    },

    {
      path: "/black-day-offer",
      element: <CelebrateOffer page={"BlackFriday"} Type="Image" />,
      // loader: "",
      // key: "employee-directory-365",
    },
    {
      path: "/cubic-anniversary-offer",
      element: <CelebrateOffer page={"CubicAnniversary"} Type="Image" />,
      // loader: "",
      // key: "employee-directory-365",
    },
    {
      path: "/asset-management-365",
      element: <AM365 />,
      // loader: "",
      // key: "asset-management-365",
    },
    {
      path: "/time-off-manager-365",
      element: <TM365 />,
      // loader: "",
      // key: "time-off-manager-365",
    },
    {
      path: "/employee-onboarding-365",
      element: <EO365 />,
      // loader: "",
      // key: "employee-onboarding-365",
    },
    {
      path: "/performance-management-365",
      element: <PM365 />,
      // loader: "",
      // key: "performance-management-365",
    },
    {
      path: "/sharepoint-timesheet",
      element: <TS365 />,
      // loader: "",
      // key: "performance-management-365",
    },
    {
      path: "helpdesk-365",
      element: <HD365 />,
      // title: "Helpdesk Software, IT Ticketing System - Helpdesk 365",
      // description: "Streamline workflows with a Microsoft helpdesk. Try now for faster ticket resolution. Helpdesk software for IT and internal support."
      // loader: "",
      // key: "helpdesk-365",
    },
    {
      path: "/privacy-policy",
      element: <Privacypolicy />,
      // loader: "",
      // key: "helpdesk-365",
    },
    {
      path: "/sharepoint-contract-management-software",
      element: <CLM />,
    },
    {
      path: "/terms-and-conditions",
      element: <Termandcondition />,
      // loader: "",
      // key: "helpdesk-365",
    },
    {
      path: "/end-user-license-agreement-eula",
      element: <EULA />,
      // loader: "",
      // key: "helpdesk-365",
    },
    {
      path: "/thank-you",
      element: <Thankyou />,
      // loader: "",
      // key: "helpdesk-365",
    },
    {
      path: "/product-addons",
      element: <ProductsAddon />,
      // loader: "",
      // key: "helpdesk-365",
    },
    {
      path: "/employee-directory",
      element: <ED1 />,
      // loader: "",
      // key: "helpdesk-365",
    },
    {
      path: "/sharepoint-employee-directory",
      element: <ED2 />,
      // loader: "",
      // key: "helpdesk-365",
    },
    {
      path: "/sharepoint-staff-directory",
      element: <ED3 />,
      // loader: "",
      // key: "helpdesk-365",
    },
    {
      path: "/microsoft-employee-directory-365",
      element: <ED4 />,
      // loader: "",
      // key: "helpdesk-365",
    },
    {
      path: "/hr-directory-365",
      element: <ED5 />,
      // loader: "",
      // key: "helpdesk-365",
    },
    {
      path: "/microsoft-365-helpdesk-software",
      element: <HD365Common sectionFirst={Helpdesk1FirstSection} Overview={Helpdesk1Overview} Metatags={Helpdesk1MetaTag} FAQs={Helpdesk1FAQs} />,
      // loader: "",
      // key: "helpdesk-365",
    },
    {
      path: "/microsoft-teams-ticketing-system",
      element: <HD365Common sectionFirst={Helpdesk2FirstSection} Overview={Helpdesk1Overview} Metatags={Helpdesk1MetaTag} FAQs={Helpdesk1FAQs}/>,
      // loader: "",
      // key: "helpdesk-365",
    },
    {
      path: "/sharepoint-ticketing-system",
      element: <HD3 />,
      // loader: "",
      // key: "helpdesk-365",
    },
    {
      path: "/microsoft-ticketing-system",
      element: <HD4 />,
      // loader: "",
      // key: "helpdesk-365",
    },
    {
      path: "/azure-devops-ticketing-system",
      element: <HD5 />,
      // loader: "",
      // key: "helpdesk-365",
    },
    {
      path: "/helpdesk-ticketing-system",
      element: <HD7 />,
      // loader: "",
      // key: "helpdesk-365",
    },
    {
      path: "/microsoft-365-ticketing-system",
      element: <HD8 />,
      // loader: "",
      // key: "helpdesk-365",
    },
    {
      path: "/it-service-ticket-software",
      element: <HD9 />,
      // loader: "",
      // key: "helpdesk-365",
    },
    {
      path: "/it-ticketing-system",
      element: <HD10 />,
      // loader: "",
      // key: "helpdesk-365",
    },
    {
      path: "/sharepoint-helpdesk-software",
      element: <HD11 />,
      // loader: "",
      // key: "helpdesk-365",
    },
    {
      path: "/sharepoint-it-ticketing-system",
      element: <HD12 />,
      // loader: "",
      // key: "helpdesk-365",
    },
    {
      path: "/time-tracking-1",
      element: <TS1 />,
      // loader: "",
      // key: "helpdesk-365",
    },
    {
      path: "/time-tracking-2",
      element: <TS2 />,
      // loader: "",
      // key: "helpdesk-365",
    },
    {
      path: "/time-tracking-3",
      element: <TS3 />,
      // loader: "",
      // key: "helpdesk-365",
    },
    {
      path: "/sharepoint-asset-management-1",
      element: <AM1 />,
      // loader: "",
      // key: "helpdesk-365",
    },
    {
      path: "/sharepoint-asset-management-2",
      element: <AM2 />,
      // loader: "",
      // key: "helpdesk-365",
    },
    {
      path: "/sharepoint-asset-management-3",
      element: <AM3 />,
      // loader: "",
      // key: "helpdesk-365",
    },
  ]);
  return (
    <>
      <div>
        {/* <Header/> */}
        <RouterProvider router={router} />
        {/* <Footer /> */}
      </div>
    </>
  );
}
export default NavBar;
