//Helpdesk1 or /microsoft-365-helpdesk-software

export const Helpdesk1FirstSection = {
  MainHeading:
    "First HD Smarter Support, Happier Teams – Faster Ticketing with Helpdesk 365",
  MainDescription:
    "Manage tickets easily with our Microsoft 365 helpdesk that works perfectly with Microsoft 365. Help your team give faster and better support without any hassle.",
  MainImageAlt: "Helpdesk 365",
  MainImageSrc:
    "https://ik.imagekit.io/zn4au2jftpm5/Apps365/productPAGES/Helpdesk_OjlohM6cD.png",
};

export const Helpdesk1Overview = {
  MainHeading:
    "Experience the Perfect Blend of AI Smarts and Human Care with Help Desk 365",
  MainDescription:
    "Get the best of AI smart and human touch—deliver happiness right out of the box. Our SharePoint ticketing system gets you going fast with simplified workflows that let your employees glide through tasks effortlessly. As well as you can use our Help desk software easily within Microsoft 365 apps like Teams, Outlook, and SharePoint.",
  MainImageAlt: "Teams Helpdesk 365",
  MainImageSrc:
    "https://ik.imagekit.io/zn4au2jftpm5/hr365/SPFxEDP/site-Helpdesk/HD365%20Teams_bGn7Gudo4J.png?updatedAt=1701258105168",
};

export const Helpdesk1FAQs = [
  {
    title: " How do I create a ticket in Microsoft?",
    content: (
      <ul>
        To create a ticket in Microsoft Helpdesk 365:
        <li>Open Microsoft Teams and go to the Helpdesk 365 app.</li>
        <li>Click New Ticket to start.</li>
        <li>
          Fill in the details, including the issue, priority, and any
          attachments.
        </li>
        <li>Submit the ticket, and it’s instantly logged.</li>
        You can track updates and communicate with the support team directly in
        Teams.
      </ul>
    ),
  },
  {
    title: "Can you use Microsoft Teams as a ticketing system?",
    content:
      "Using Microsoft Teams as a helpdesk 365 ticketing system enables employees to get instant support and resolve issues faster. Both employees and the support team collaborate easily on the same platform, with the added benefit of 24/7 accessibility for continuous assistance.",
  },
  {
    title: "Is SharePoint a Ticketing System?",
    content: (
      <ul>
        SharePoint a collaboration platform for managing documents and workflows
        for millions of Microsoft 365 users. However, you can customize it for
        ticket tracking and effective communication using:
        <li>Lists: To log and track tickets.</li>
        <li>Workflows: For automating assignments and updates.</li>
        <li>Power Automate: For advanced automation.</li>
        For a more robust ticketing solution, tools like Helpdesk 365 integrate
        seamlessly with SharePoint. So that you can easily manage your
        SharePoint ticketing system with more efficiency.
      </ul>
    ),
  },
  {
    title: "What is a SharePoint system?",
    content:
      "SharePoint is a web-based platform that improves your organization efficiency by simplifying data management and access. SharePoint serves as an enterprise information hub, easily customizable to support intranet, extranet, and internet sites.",
  },
  {
    title: "Which Tools is SharePoint?",
    content:
      "Organizations use Microsoft SharePoint to create websites. You can use it as a secure place to store, organize, share, and access information from any device. All you need is a web browser, such as Microsoft Edge, Internet Explorer, Chrome, or Firefox. Want to learn more?",
  },
];

export const Helpdesk1MetaTag = {
    Title: "Helpdesk Software for Microsoft 365 | Fast, Secure, and Reliable",
    Description: "Helpdesk 365: Secure ticketing for IT, HR, and Finance. Seamlessly integrates with Microsoft 365. Easy to deploy, customizable, and efficient."
}

export const Helpdesk1Features = 
[{
    MainHeading: 'Feature1',
    MainDescription: "Description1",
    MainImageSrc: "",
    MainImageAlt:"MainImage1"
},
{
    MainHeading: 'Feature2',
    MainDescription: "Description2",
    MainImageSrc: "",
    MainImageAlt:"MainImage2"
}
]
// Second Page

export const Helpdesk2FirstSection = {
    MainHeading:
      "Second HD Smarter Support, Happier Teams – Faster Ticketing with Helpdesk 365",
    MainDescription:
      "Manage tickets easily with our Microsoft 365 helpdesk that works perfectly with Microsoft 365. Help your team give faster and better support without any hassle.",
    MainImageAlt: "Helpdesk 365",
    MainImageSrc:
      "https://ik.imagekit.io/zn4au2jftpm5/Apps365/productPAGES/Helpdesk_OjlohM6cD.png",
  };